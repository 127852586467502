.copyrights {
  font-size: 1.2rem;
  padding: 30px;
  color: $color-dogwoodRose;
  text-align: center;

  .icon-favorite {
    position: relative;
  }
  .icon-canada {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -57%);
    font-size: 4.8rem;
    opacity: 0.3;
  }
  p {
    margin: 10px;
  }
}