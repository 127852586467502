@font-face {
  font-family: 'icons';
  src:  url('../../fonts/icons/icons.eot?fuh35d');
  src:  url('../../fonts/icons/icons.eot?fuh35d#iefix') format('embedded-opentype'),
    url('../../fonts/icons/icons.ttf?fuh35d') format('truetype'),
    url('../../fonts/icons/icons.woff?fuh35d') format('woff'),
    url('../../fonts/icons/icons.svg?fuh35d#icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-favorite:before {
  content: "\e900";
}
.icon-trash:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-settings:before {
  content: "\e904";
}
.icon-canada:before {
  content: "\e905";
}
.icon-infos:before {
  content: "\e906";
}
.icon-web:before {
  content: "\e907";
}
.icon-facebook:before {
  content: "\e908";
}
.icon-instagram:before {
  content: "\e909";
}
.icon-direction:before {
  content: "\e90a";
}
.icon-filter:before {
  content: "\e90b";
}
.icon-check:before {
  content: "\e90c";
}
.icon-cross:before {
  content: "\e90d";
}
.icon-list:before {
  content: "\e90e";
}
.icon-pin:before {
  content: "\e90f";
}
.icon-sad .path1:before {
  content: "\e910";
  opacity: 0.6;
}
.icon-sad .path2:before {
  content: "\e911";
  margin-left: -1.412109375em;
}
.icon-sad .path3:before {
  content: "\e912";
  margin-left: -1.412109375em;
}
.icon-sad .path4:before {
  content: "\e913";
  margin-left: -1.412109375em;
  opacity: 0.8;
}
.icon-sad .path5:before {
  content: "\e914";
  margin-left: -1.412109375em;
  opacity: 0.8;
}
.icon-sad .path6:before {
  content: "\e915";
  margin-left: -1.412109375em;
  opacity: 0.8;
}
.icon-criteria-deco .path1:before {
  content: "\e916";
  opacity: 0.6;
}
.icon-criteria-deco .path2:before {
  content: "\e917";
  margin-left: -1em;
}
.icon-criteria-deco .path3:before {
  content: "\e918";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-deco .path4:before {
  content: "\e919";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-vibes .path1:before {
  content: "\e91a";
  opacity: 0.7;
}
.icon-criteria-vibes .path2:before {
  content: "\e91b";
  margin-left: -1em;
}
.icon-criteria-vibes .path3:before {
  content: "\e91c";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-vibes .path4:before {
  content: "\e91d";
  margin-left: -1em;
  opacity: 0.4;
}
.icon-criteria-vibes .path5:before {
  content: "\e91e";
  margin-left: -1em;
  opacity: 0.4;
}
.icon-criteria-vibes .path6:before {
  content: "\e91f";
  margin-left: -1em;
  opacity: 0.4;
}
.icon-criteria-vibes .path7:before {
  content: "\e920";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-vibes .path8:before {
  content: "\e921";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-vibes .path9:before {
  content: "\e922";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-vibes .path10:before {
  content: "\e923";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-vibes .path11:before {
  content: "\e924";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-vibes .path12:before {
  content: "\e925";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-vibes .path13:before {
  content: "\e926";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-vibes .path14:before {
  content: "\e927";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-vibes .path15:before {
  content: "\e928";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-vibes .path16:before {
  content: "\e929";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-vibes .path17:before {
  content: "\e92a";
  margin-left: -1em;
  opacity: 0.4;
}
.icon-criteria-vibes .path18:before {
  content: "\e92b";
  margin-left: -1em;
  opacity: 0.4;
}
.icon-criteria-vibes .path19:before {
  content: "\e92c";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-quality .path1:before {
  content: "\e92d";
}
.icon-criteria-quality .path2:before {
  content: "\e92e";
  margin-left: -1em;
}
.icon-criteria-quality .path3:before {
  content: "\e92f";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-quality .path4:before {
  content: "\e930";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path5:before {
  content: "\e931";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path6:before {
  content: "\e932";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path7:before {
  content: "\e933";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path8:before {
  content: "\e934";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path9:before {
  content: "\e935";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path10:before {
  content: "\e936";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path11:before {
  content: "\e937";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-quality .path12:before {
  content: "\e938";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-quality .path13:before {
  content: "\e939";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-quality .path14:before {
  content: "\e93a";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-quality .path15:before {
  content: "\e93b";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-quality .path16:before {
  content: "\e93c";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-price .path1:before {
  content: "\e93d";
}
.icon-criteria-price .path2:before {
  content: "\e93e";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-price .path3:before {
  content: "\e93f";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-price .path4:before {
  content: "\e940";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-price .path5:before {
  content: "\e941";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-price .path6:before {
  content: "\e942";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-price .path7:before {
  content: "\e943";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-price .path8:before {
  content: "\e944";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-price .path9:before {
  content: "\e945";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-price .path10:before {
  content: "\e946";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-price .path11:before {
  content: "\e947";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-transports .path1:before {
  content: "\e948";
  opacity: 0.8;
}
.icon-criteria-transports .path2:before {
  content: "\e949";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-transports .path3:before {
  content: "\e94a";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-transports .path4:before {
  content: "\e94b";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-transports .path5:before {
  content: "\e94c";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-transports .path6:before {
  content: "\e94d";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-transports .path7:before {
  content: "\e94e";
  margin-left: -1em;
}
.icon-criteria-transports .path8:before {
  content: "\e94f";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-speed .path1:before {
  content: "\e950";
}
.icon-criteria-speed .path2:before {
  content: "\e951";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-speed .path3:before {
  content: "\e952";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-speed .path4:before {
  content: "\e953";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-speed .path5:before {
  content: "\e954";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-speed .path6:before {
  content: "\e955";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-speed .path7:before {
  content: "\e956";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-speed .path8:before {
  content: "\e957";
  margin-left: -1em;
}
.icon-criteria-nice .path1:before {
  content: "\e958";
}
.icon-criteria-nice .path2:before {
  content: "\e959";
  margin-left: -1em;
}
.icon-criteria-nice .path3:before {
  content: "\e95a";
  margin-left: -1em;
}
.icon-criteria-nice .path4:before {
  content: "\e95b";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-nice .path5:before {
  content: "\e95c";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-nice .path6:before {
  content: "\e95d";
  margin-left: -1em;
  opacity: 0.9;
}
.icon-criteria-nice .path7:before {
  content: "\e95e";
  margin-left: -1em;
  opacity: 0.9;
}
.icon-criteria-nice .path8:before {
  content: "\e95f";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-nice .path9:before {
  content: "\e960";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-nice .path10:before {
  content: "\e961";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-nice .path11:before {
  content: "\e962";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-size .path1:before {
  content: "\e963";
  opacity: 0.6;
}
.icon-criteria-size .path2:before {
  content: "\e964";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-size .path3:before {
  content: "\e965";
  margin-left: -1em;
}
.icon-criteria-size .path4:before {
  content: "\e966";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-size .path5:before {
  content: "\e967";
  margin-left: -1em;
}
.icon-criteria-size .path6:before {
  content: "\e968";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-size .path7:before {
  content: "\e969";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-size .path8:before {
  content: "\e96a";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-size .path9:before {
  content: "\e96b";
  margin-left: -1em;
  opacity: 0.9;
}
.icon-criteria-size .path10:before {
  content: "\e96c";
  margin-left: -1em;
  opacity: 0.9;
}
.icon-criteria-noise .path1:before {
  content: "\e96d";
}
.icon-criteria-noise .path2:before {
  content: "\e96e";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-noise .path3:before {
  content: "\e96f";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-noise .path4:before {
  content: "\e970";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-byow .path1:before {
  content: "\e971";
}
.icon-criteria-byow .path2:before {
  content: "\e972";
  margin-left: -1em;
}
.icon-criteria-byow .path3:before {
  content: "\e973";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path4:before {
  content: "\e974";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path5:before {
  content: "\e975";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path6:before {
  content: "\e976";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path7:before {
  content: "\e977";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path8:before {
  content: "\e978";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-byow .path9:before {
  content: "\e979";
  margin-left: -1em;
}
.icon-criteria-byow .path10:before {
  content: "\e97a";
  margin-left: -1em;
}
.icon-criteria-byow .path11:before {
  content: "\e97b";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-byow .path12:before {
  content: "\e97c";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-byow .path13:before {
  content: "\e97d";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-byow .path14:before {
  content: "\e97e";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-byow .path15:before {
  content: "\e97f";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path16:before {
  content: "\e980";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path17:before {
  content: "\e981";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path18:before {
  content: "\e982";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path19:before {
  content: "\e983";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path20:before {
  content: "\e984";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path21:before {
  content: "\e985";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-byow .path22:before {
  content: "\e986";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-cards .path1:before {
  content: "\e987";
}
.icon-criteria-cards .path2:before {
  content: "\e988";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-criteria-cards .path3:before {
  content: "\e989";
  margin-left: -1em;
  opacity: 0.8;
}
.icon-criteria-cards .path4:before {
  content: "\e98a";
  margin-left: -1em;
  opacity: 0.6;
}
.icon-criteria-cards .path5:before {
  content: "\e98b";
  margin-left: -1em;
  opacity: 0.7;
}
.icon-pin-restaurant .path1:before {
  content: "\e98c";
}
.icon-pin-restaurant .path2:before {
  content: "\e98d";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-restaurant .path3:before {
  content: "\e98e";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-bar .path1:before {
  content: "\e98f";
}
.icon-pin-bar .path2:before {
  content: "\e990";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-bar .path3:before {
  content: "\e991";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-bar .path4:before {
  content: "\e992";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-coffee .path1:before {
  content: "\e993";
}
.icon-pin-coffee .path2:before {
  content: "\e994";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-coffee .path3:before {
  content: "\e995";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-coffee .path4:before {
  content: "\e996";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
.icon-pin-coffee .path5:before {
  content: "\e997";
  margin-left: -0.7607421875em;
  opacity: 0.7;
}
