.places-list {
  padding-top: 130px;

  p {
    padding: 10px 30px;
    font-size: 1.2rem;
  }
  li.city {
    background-color: rgba($color-japaneseIndigo, 0.2);
    text-transform: uppercase;
    padding: 5px 15px;
    font-size: 1.2rem;
    font-weight: $font-weight-bold;
  }
  li.place {
    display: flex;
    align-items: center;
    padding: 10px 30px;

    &:nth-child(even) {
      background-color: rgba($color-white, 0.2);
    }
    .pin {
      margin-right: 10px;
    }
    .name {
      font-weight: $font-weight-bold;
    }
    .distance {
      white-space: nowrap;
      display: block;
      margin-left: auto;
      font-size: 1.2rem;
    }
  }
  &.no-place {
    display: flex;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;

    i {
      display: block;
      font-size: 10rem;
      color: $color-white;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.8rem;
      padding: 0;
      margin-bottom: 10px;

      strong {
        font-size: 2rem;
      }
    }
  }
}