.stars-container {
  position: relative;
  display: inline-flex;

  .empty-stars,
  .full-stars {
    display: flex;

    .star::before {
      content: '★ ';
    }
  }
  .empty-stars {
    color: rgba($color-white, 0.4);
  }
  .full-stars {
    position: absolute;
    color: #ffc700;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

.rate {
  display: flex;
  flex-direction: row-reverse;
  height: 46px;
  padding: 0 10px;
  margin: 10px 0 0;

  &:not(:checked) {
    > input {
      position: absolute;
      top: -9999px;
    }
    > label {
      width: 1em;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      font-size: 46px;
      line-height: 1;
      color: $color-white;

      &:before {
        content: '★ ';
      }
      &:hover,
      &:hover ~ label {
          color: #deb217;
      }
    }
  }
  > input:checked ~ label {
    color: #ffc700;
  }
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}