.filters {
  position: fixed;
  bottom: 30px;
  left: 30px;
  background-color: $color-white;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba($color-black, 0.1);
  z-index: 1;

  label {
    display: block;
    width: 100%;
    transition: .3s all;

    span {
      display: flex;
      align-items: center;
      padding: 0 15px 0 5px;

      &::before {
        content: '\e90d';
        display: block;
        font-family: 'icons';
        font-size: 2rem;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin-right: 5px;
      }
    }
    input[type="checkbox"] {
      position: absolute;
      background: transparent;
      border: 0;

      &:checked + span::before {
        content: '\e90c';
        font-size: 1.6rem;
      }
    }
  }
  .bar {
    &.active {
      color: $color-white;
      background-color: $color-bar;
    }
  }
  .restaurant {
    &.active {
      color: $color-white;
      background-color: $color-restaurant;
    }
  }
  .coffee {
    &.active {
      color: $color-white;
      background-color: $color-coffee;
    }
  }
}