.App {
  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 140px;
    background-image: url(../../images/bg-header.png);
    background-size: auto 101%;
    background-repeat: repeat-x;
    z-index: 2;

    @media screen and (min-width: 768px) {
      height: 105px;
    }
  }
}

.logo {
  height: 60px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  filter: drop-shadow(0 10px 20px rgba($color-black, 0.2));

  @media screen and (min-width: 768px) {
    height: 80px;
    left: 30px;
    transform: none;
  }
}