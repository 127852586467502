// Close buttons
.reset-search-button,
.close-modal-button {
  position: absolute;
  text-indent: -9999px;
  background: transparent;
  border: 0;
  top: 0;
  right: 0;
  z-index: 2;

  &::before,
  &::after {
    content: '';
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.close-modal-button {
  width: 62px;
  height: 62px;

  @media screen and (min-width: 768px) {
    width: 102px;
    height: 102px;
  }
  &::before,
  &::after {
    width: 32px;
    background-color: $color-white;
  }
}

.reset-search-button {
  width: 40px;
  height: 40px;
  z-index: 2;

  &::before,
  &::after {
    width: 18px;
    background-color: $color-japaneseIndigo;
  }
}

// Buttons in marker popup
.mapboxgl-popup {
  .favorite-button {
    border: 0;
    width: 50%;
    height: 32px;
    border-radius: 0;
  }
  .trash-button {
    background-color: $color-dogwoodRose;
    color: $color-white;
    border: 0;
    border-radius: 0;
    flex: 1 1 50%;
    padding: 5px 10px;
  }
}

// Default actions buttons
.btn {
  background-color: $color-canary;
  border: 0;
  appearance: none;
  color: $color-japaneseIndigo;
  font-weight: $font-weight-normal;
  padding: 10px 20px;
  margin: 10px 0;
  transition: .3s all;
  display: block;
  width: 100%;
  border-radius: 2px;
  line-height: 20px;
  text-decoration: none;

  @media screen and (min-width: 768px) {
    max-width: 320px;
    display: inline-block;
    margin: 0;
  }
  &.squared {
    padding: 10px 0;
    width: 43px;
    height: 43px;
  }
  &.secondary {
    background-color: rgba($color-white, 0.8);
  }
  &.red {
    color: $color-white;
    background-color: $color-dogwoodRose;
  }
  &.facebook {
    background-color: $color-white;
    position: relative;
    padding-left: 60px;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      color: $color-white;
      background-color: $color-japaneseIndigo;
      border: 1px solid $color-white;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }
}

.App .mapboxgl-ctrl-group > button {
  &,
  &:hover {
    background-color: $color-canary;
  }
}

.no-style {
  background: transparent;
  border: 0;
  appearance: none;
  padding: 10px 20px;
}

.AppHeader {
  > .actions {
    &::before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      width: 0;
      height: 0;
      border-radius: 50%;
      background-color: rgba($color-japaneseIndigo, 0.7);
      z-index: 6;
      transition: .3s all;
      opacity: 0;
      visibility: hidden;
    }
    button {
      width: 30px;
      height: 30px;
      display: block;
      padding: 0;
      outline: none;
      border: 0;
      background-color: $color-canary;
      box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
      position: fixed;
      z-index: 7;
      top: 25px;
      right: 30px;

      @media screen and (min-width: 768px) {
        top: 35px;
        right: 90px;
      }
      i {
        line-height: 30px;
      }
      &:not(.star-button) {
        opacity: 0;
        visibility: hidden;
      }
      &.star-button {
        z-index: 8;

        &::before {
          content: '★';
        }
      }
    }
    &.active {
      &::before {
        top: -200px;
        right: -200px;
        width: 400px;
        height: 400px;
        opacity: 1;
        visibility: visible;
      }
      button {
        opacity: 1;
        visibility: visible;
      }
      button.mode-button {
        right: 90px;

        @media screen and (min-width: 768px) {
          top: 90px;
          right: 30px;
        }
      }
      button.settings-button {
        top: 85px;
        right: 90px;

        @media screen and (min-width: 768px) {
          top: 35px;
          right: 30px;
        }
      }
      button.filter-button {
        top: 85px;

        @media screen and (min-width: 768px) {
          top: 90px;
        }
      }
      button.star-button {
        &::before {
          display: none;
        }
      }
    }
  }
}
