@import url('https://fonts.googleapis.com/css?family=#{$font-primary}:200,400,800&display=swap');

strong {
  font-weight: $font-weight-bold;
}

.big-title {
  font-size: 3.2rem;
  font-weight: $font-weight-light;
}

a {
  color: $color-seaFoamGreen;
}

.color-green {
  color: $color-mediumAquamarine;
}

.color-red {
  color: $color-dogwoodRose;
}

h1,
h2 {
  color: $color-canary;
}

h2 {
  font-weight: $font-weight-light;
}

h3 {
  font-weight: $font-weight-normal;
}