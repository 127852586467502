// - Font
$font-primary: 'Montserrat';
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 800;

// - Colors
$color-bar: #786FA6;
$color-coffee: #F19066;
$color-restaurant: #C44569;

$color-seaFoamGreen: #B9E3C6;
$color-mediumAquamarine: #59C9A5;
$color-dogwoodRose: #D81E5B;
$color-japaneseIndigo: #23395B;
$color-canary: #FFFD98;

$color-black: #000;
$color-white: #fff;