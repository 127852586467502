* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;

  &:focus {
    outline: 0;
  }
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: $font-primary, sans-serif;
  background-color: $color-seaFoamGreen;
  color: $color-japaneseIndigo;
  touch-action: manipulation;
}

.App {
  width: 100vw;
  height: 100vh;
}

a,
button {
  &:focus {
    box-shadow: 0 0 0 3px rgba($color-seaFoamGreen, 0.4);
  }
}
