input,
button {
  appearance: none;
  font-size: 1.6rem;
  font-family: $font-primary, sans-serif;
  transition: .3s all;
  border-radius: 2px;
  width: 100%;
  max-width: 320px;
}

button {
  cursor: pointer;
}

input[type="email"],
input[type="text"],
input[type="password"] {
  color: $color-canary;
  text-overflow: ellipsis;
  display: block;
  padding: 10px 20px;
  border: 1px solid $color-seaFoamGreen;
  background: rgba($color-seaFoamGreen, 0.2);
  position: relative;
  z-index: 1;
  line-height: 20px;

  &:focus {
    border-color: $color-canary;
  }
}

::placeholder {
  color: $color-seaFoamGreen;
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 1.6rem;
  border: 1px solid $color-canary;
  -webkit-text-fill-color: $color-canary;
  transition: background-color 5000s ease-in-out 0s;
}

.search {
  position: fixed;
  left: 30px;
  right: 30px;
  top: 80px;
  box-shadow: 0 10px 30px 0 rgba($color-black, 0.1);
  border-radius: 20px;
  z-index: 5;

  @media screen and (min-width: 768px) {
    max-width: 370px;
    width: 100%;
    left: auto;
    top: 30px;
    right: 150px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 420px;
  }
  input {
    padding: 10px 15px;
    max-width: inherit;
    background-color: rgba($color-white, 0.6);
    color: $color-japaneseIndigo;

    &::placeholder {
      color: $color-japaneseIndigo;
      opacity: 1;
    }
    &:focus {
      background-color: $color-white;
    }
  }
  .list-suggestions {
    display: block;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: $color-white;
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    transition: .3s all;

    li {
      padding: 8px 15px;
      border-top: 1px solid rgba($color-black, 0.1);

      strong {
        display: block;
      }
      div {
        opacity: .6;
        font-size: 1.2rem;
      }
    }
  }
  &.with-value {
    input {
      padding: 10px 40px 10px 15px;
    }
  }
}

.list-radios {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0;
  opacity: 0;
  visibility: hidden;
  transition: .3s all;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

// Radios true / false
.radio-toggles {
  display: flex;
  justify-content: center;

  > div {
    position: relative;
    margin: 20px 10px;

    label {
      font-size: 2rem;
      display: block;
      border: 2px solid $color-white;
      border-radius: 5px;
      cursor: pointer;
      padding: 8px 15px;
      transition: .3s all;
      position: relative;
      text-indent: -9999px;
      width: 60px;
      height: 60px;
    }
    input {
      opacity: 0;
      position: absolute;

      &:checked + label {
        background-color: $color-white;
      }
    }
    &.radio-false {
      label {
        &::before,
        &::after {
          content: '';
          height: 3px;
          width: 33px;
          position: absolute;
          top: 50%;
          left: 50%;
          background-color: $color-white;
        }
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
    &.radio-true {
      label {
        &::after {
          content: '';
          opacity: 1;
          height: 30px;
          width: 15px;
          transform-origin: 50% 50%;
          border-left: 3px solid $color-white;
          border-top: 3px solid $color-white;
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -68%) rotate(-135deg);
        }
      }
    }
  }
}

.LoginPage,
.RegisterPage,
.PrivacyPage {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  text-align: center;
  background-image: url(../../images/bg-login.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;

  .content {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .loginForm,
    .registerForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
      margin-top: 80px;
      padding: 0 30px;

      > *:not(:last-child) {
        margin-bottom: 20px;
        margin-top: 0;
      }
      .error {
        color: #e74c3c;
      }
      input {
        text-align: center;
      }
    }
  }
}

.PrivacyPage {
  padding: 150px 30px 50px;
  color: white;
  text-align: left;

  p,
  ul {
    margin: 20px 0;
  }
  ul {
    li {
      list-style: initial;
      margin: 10px 20px;
    }
  }
}