@import url(https://fonts.googleapis.com/css?family=Montserrat:200,400,800&display=swap);
@charset "UTF-8";
@-webkit-keyframes rotate {
  100% {
    transform: rotate(360deg); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0; }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px; }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px; } }

@font-face {
  font-family: 'icons';
  src: url(/static/media/icons.a34383e1.eot);
  src: url(/static/media/icons.a34383e1.eot) format("embedded-opentype"), url(/static/media/icons.cfa6f818.ttf) format("truetype"), url(/static/media/icons.36d3304c.woff) format("woff"), url(/static/media/icons.4a4e0cf5.svg) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-favorite:before {
  content: "\e900"; }

.icon-trash:before {
  content: "\e901"; }

.icon-arrow-left:before {
  content: "\e902"; }

.icon-arrow-right:before {
  content: "\e903"; }

.icon-settings:before {
  content: "\e904"; }

.icon-canada:before {
  content: "\e905"; }

.icon-infos:before {
  content: "\e906"; }

.icon-web:before {
  content: "\e907"; }

.icon-facebook:before {
  content: "\e908"; }

.icon-instagram:before {
  content: "\e909"; }

.icon-direction:before {
  content: "\e90a"; }

.icon-filter:before {
  content: "\e90b"; }

.icon-check:before {
  content: "\e90c"; }

.icon-cross:before {
  content: "\e90d"; }

.icon-list:before {
  content: "\e90e"; }

.icon-pin:before {
  content: "\e90f"; }

.icon-sad .path1:before {
  content: "\e910";
  opacity: 0.6; }

.icon-sad .path2:before {
  content: "\e911";
  margin-left: -1.412109375em; }

.icon-sad .path3:before {
  content: "\e912";
  margin-left: -1.412109375em; }

.icon-sad .path4:before {
  content: "\e913";
  margin-left: -1.412109375em;
  opacity: 0.8; }

.icon-sad .path5:before {
  content: "\e914";
  margin-left: -1.412109375em;
  opacity: 0.8; }

.icon-sad .path6:before {
  content: "\e915";
  margin-left: -1.412109375em;
  opacity: 0.8; }

.icon-criteria-deco .path1:before {
  content: "\e916";
  opacity: 0.6; }

.icon-criteria-deco .path2:before {
  content: "\e917";
  margin-left: -1em; }

.icon-criteria-deco .path3:before {
  content: "\e918";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-deco .path4:before {
  content: "\e919";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-vibes .path1:before {
  content: "\e91a";
  opacity: 0.7; }

.icon-criteria-vibes .path2:before {
  content: "\e91b";
  margin-left: -1em; }

.icon-criteria-vibes .path3:before {
  content: "\e91c";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-vibes .path4:before {
  content: "\e91d";
  margin-left: -1em;
  opacity: 0.4; }

.icon-criteria-vibes .path5:before {
  content: "\e91e";
  margin-left: -1em;
  opacity: 0.4; }

.icon-criteria-vibes .path6:before {
  content: "\e91f";
  margin-left: -1em;
  opacity: 0.4; }

.icon-criteria-vibes .path7:before {
  content: "\e920";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-vibes .path8:before {
  content: "\e921";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-vibes .path9:before {
  content: "\e922";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-vibes .path10:before {
  content: "\e923";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-vibes .path11:before {
  content: "\e924";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-vibes .path12:before {
  content: "\e925";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-vibes .path13:before {
  content: "\e926";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-vibes .path14:before {
  content: "\e927";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-vibes .path15:before {
  content: "\e928";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-vibes .path16:before {
  content: "\e929";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-vibes .path17:before {
  content: "\e92a";
  margin-left: -1em;
  opacity: 0.4; }

.icon-criteria-vibes .path18:before {
  content: "\e92b";
  margin-left: -1em;
  opacity: 0.4; }

.icon-criteria-vibes .path19:before {
  content: "\e92c";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-quality .path1:before {
  content: "\e92d"; }

.icon-criteria-quality .path2:before {
  content: "\e92e";
  margin-left: -1em; }

.icon-criteria-quality .path3:before {
  content: "\e92f";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-quality .path4:before {
  content: "\e930";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path5:before {
  content: "\e931";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path6:before {
  content: "\e932";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path7:before {
  content: "\e933";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path8:before {
  content: "\e934";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path9:before {
  content: "\e935";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path10:before {
  content: "\e936";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path11:before {
  content: "\e937";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-quality .path12:before {
  content: "\e938";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-quality .path13:before {
  content: "\e939";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-quality .path14:before {
  content: "\e93a";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-quality .path15:before {
  content: "\e93b";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-quality .path16:before {
  content: "\e93c";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-price .path1:before {
  content: "\e93d"; }

.icon-criteria-price .path2:before {
  content: "\e93e";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-price .path3:before {
  content: "\e93f";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-price .path4:before {
  content: "\e940";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-price .path5:before {
  content: "\e941";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-price .path6:before {
  content: "\e942";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-price .path7:before {
  content: "\e943";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-price .path8:before {
  content: "\e944";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-price .path9:before {
  content: "\e945";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-price .path10:before {
  content: "\e946";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-price .path11:before {
  content: "\e947";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-transports .path1:before {
  content: "\e948";
  opacity: 0.8; }

.icon-criteria-transports .path2:before {
  content: "\e949";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-transports .path3:before {
  content: "\e94a";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-transports .path4:before {
  content: "\e94b";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-transports .path5:before {
  content: "\e94c";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-transports .path6:before {
  content: "\e94d";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-transports .path7:before {
  content: "\e94e";
  margin-left: -1em; }

.icon-criteria-transports .path8:before {
  content: "\e94f";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-speed .path1:before {
  content: "\e950"; }

.icon-criteria-speed .path2:before {
  content: "\e951";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-speed .path3:before {
  content: "\e952";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-speed .path4:before {
  content: "\e953";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-speed .path5:before {
  content: "\e954";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-speed .path6:before {
  content: "\e955";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-speed .path7:before {
  content: "\e956";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-speed .path8:before {
  content: "\e957";
  margin-left: -1em; }

.icon-criteria-nice .path1:before {
  content: "\e958"; }

.icon-criteria-nice .path2:before {
  content: "\e959";
  margin-left: -1em; }

.icon-criteria-nice .path3:before {
  content: "\e95a";
  margin-left: -1em; }

.icon-criteria-nice .path4:before {
  content: "\e95b";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-nice .path5:before {
  content: "\e95c";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-nice .path6:before {
  content: "\e95d";
  margin-left: -1em;
  opacity: 0.9; }

.icon-criteria-nice .path7:before {
  content: "\e95e";
  margin-left: -1em;
  opacity: 0.9; }

.icon-criteria-nice .path8:before {
  content: "\e95f";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-nice .path9:before {
  content: "\e960";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-nice .path10:before {
  content: "\e961";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-nice .path11:before {
  content: "\e962";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-size .path1:before {
  content: "\e963";
  opacity: 0.6; }

.icon-criteria-size .path2:before {
  content: "\e964";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-size .path3:before {
  content: "\e965";
  margin-left: -1em; }

.icon-criteria-size .path4:before {
  content: "\e966";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-size .path5:before {
  content: "\e967";
  margin-left: -1em; }

.icon-criteria-size .path6:before {
  content: "\e968";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-size .path7:before {
  content: "\e969";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-size .path8:before {
  content: "\e96a";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-size .path9:before {
  content: "\e96b";
  margin-left: -1em;
  opacity: 0.9; }

.icon-criteria-size .path10:before {
  content: "\e96c";
  margin-left: -1em;
  opacity: 0.9; }

.icon-criteria-noise .path1:before {
  content: "\e96d"; }

.icon-criteria-noise .path2:before {
  content: "\e96e";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-noise .path3:before {
  content: "\e96f";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-noise .path4:before {
  content: "\e970";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-byow .path1:before {
  content: "\e971"; }

.icon-criteria-byow .path2:before {
  content: "\e972";
  margin-left: -1em; }

.icon-criteria-byow .path3:before {
  content: "\e973";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path4:before {
  content: "\e974";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path5:before {
  content: "\e975";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path6:before {
  content: "\e976";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path7:before {
  content: "\e977";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path8:before {
  content: "\e978";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-byow .path9:before {
  content: "\e979";
  margin-left: -1em; }

.icon-criteria-byow .path10:before {
  content: "\e97a";
  margin-left: -1em; }

.icon-criteria-byow .path11:before {
  content: "\e97b";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-byow .path12:before {
  content: "\e97c";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-byow .path13:before {
  content: "\e97d";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-byow .path14:before {
  content: "\e97e";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-byow .path15:before {
  content: "\e97f";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path16:before {
  content: "\e980";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path17:before {
  content: "\e981";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path18:before {
  content: "\e982";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path19:before {
  content: "\e983";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path20:before {
  content: "\e984";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path21:before {
  content: "\e985";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-byow .path22:before {
  content: "\e986";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-cards .path1:before {
  content: "\e987"; }

.icon-criteria-cards .path2:before {
  content: "\e988";
  margin-left: -1em;
  opacity: 0.7; }

.icon-criteria-cards .path3:before {
  content: "\e989";
  margin-left: -1em;
  opacity: 0.8; }

.icon-criteria-cards .path4:before {
  content: "\e98a";
  margin-left: -1em;
  opacity: 0.6; }

.icon-criteria-cards .path5:before {
  content: "\e98b";
  margin-left: -1em;
  opacity: 0.7; }

.icon-pin-restaurant .path1:before {
  content: "\e98c"; }

.icon-pin-restaurant .path2:before {
  content: "\e98d";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-restaurant .path3:before {
  content: "\e98e";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-bar .path1:before {
  content: "\e98f"; }

.icon-pin-bar .path2:before {
  content: "\e990";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-bar .path3:before {
  content: "\e991";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-bar .path4:before {
  content: "\e992";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-coffee .path1:before {
  content: "\e993"; }

.icon-pin-coffee .path2:before {
  content: "\e994";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-coffee .path3:before {
  content: "\e995";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-coffee .path4:before {
  content: "\e996";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.icon-pin-coffee .path5:before {
  content: "\e997";
  margin-left: -0.7607421875em;
  opacity: 0.7; }

.d-flex {
  display: flex; }

.d-block {
  display: block; }

.flex-1 {
  flex: 1 1 auto; }

strong {
  font-weight: 800; }

.big-title {
  font-size: 3.2rem;
  font-weight: 200; }

a {
  color: #B9E3C6; }

.color-green {
  color: #59C9A5; }

.color-red {
  color: #D81E5B; }

h1,
h2 {
  color: #FFFD98; }

h2 {
  font-weight: 200; }

h3 {
  font-weight: 400; }

.copyrights {
  font-size: 1.2rem;
  padding: 30px;
  color: #D81E5B;
  text-align: center; }
  .copyrights .icon-favorite {
    position: relative; }
  .copyrights .icon-canada {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -57%);
    font-size: 4.8rem;
    opacity: 0.3; }
  .copyrights p {
    margin: 10px; }

.filters {
  position: fixed;
  bottom: 30px;
  left: 30px;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  z-index: 1; }
  .filters label {
    display: block;
    width: 100%;
    transition: .3s all; }
    .filters label span {
      display: flex;
      align-items: center;
      padding: 0 15px 0 5px; }
      .filters label span::before {
        content: '\e90d';
        display: block;
        font-family: 'icons';
        font-size: 2rem;
        width: 36px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        margin-right: 5px; }
    .filters label input[type="checkbox"] {
      position: absolute;
      background: transparent;
      border: 0; }
      .filters label input[type="checkbox"]:checked + span::before {
        content: '\e90c';
        font-size: 1.6rem; }
  .filters .bar.active {
    color: #fff;
    background-color: #786FA6; }
  .filters .restaurant.active {
    color: #fff;
    background-color: #C44569; }
  .filters .coffee.active {
    color: #fff;
    background-color: #F19066; }

.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 140px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAABkCAYAAABHLFpgAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MTlENjg0RDdEMTk5MTFFOTk2MEVCQjk3QTA1ODI5NkYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MTlENjg0RDZEMTk5MTFFOTk2MEVCQjk3QTA1ODI5NkYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0QjU3QTk3OUQxMTcxMUU5ODIwMTgwQUFBODZBNzZCNiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0QjU3QTk3QUQxMTcxMUU5ODIwMTgwQUFBODZBNzZCNiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PrjdEw4AAAA7SURBVHjaYlC2jD7DxAAEw4T4h4v4g4X7F5X1F4sE6cRvOIEmhpNLEvEXl8MRxD9c3P8M////ZwAIMADuBD57YoTAhQAAAABJRU5ErkJggg==);
  background-size: auto 101%;
  background-repeat: repeat-x;
  z-index: 2; }
  @media screen and (min-width: 768px) {
    .App::before {
      height: 105px; } }

.logo {
  height: 60px;
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  -webkit-filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.2)); }
  @media screen and (min-width: 768px) {
    .logo {
      height: 80px;
      left: 30px;
      transform: none; } }

.mapboxgl-ctrl {
  position: absolute;
  top: 25px;
  left: 30px;
  z-index: 2; }
  @media screen and (min-width: 768px) {
    .mapboxgl-ctrl {
      left: auto;
      top: 35px;
      right: 30px; } }

.navigation-control {
  left: auto;
  top: auto;
  bottom: 20px;
  right: 20px; }

.pin {
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: transparent;
  width: auto;
  position: relative; }
  .pin .favorite-flag {
    width: 18px;
    position: absolute;
    right: -5px;
    top: -5px; }
    @media screen and (min-width: 768px) {
      .pin .favorite-flag {
        width: 20px; } }
  .pin img:not(.favorite-flag) {
    height: 52px; }
    @media screen and (min-width: 768px) {
      .pin img:not(.favorite-flag) {
        height: 66px; } }

.mapboxgl-marker {
  z-index: 0; }
  .mapboxgl-marker.selected {
    z-index: 1; }
  .mapboxgl-marker .pin {
    position: absolute;
    top: -52px;
    left: 0;
    transform: translate(-50%, 0); }
    @media screen and (min-width: 768px) {
      .mapboxgl-marker .pin {
        top: -66px; } }

.mapboxgl-popup {
  max-width: 200px;
  margin-top: -52px;
  z-index: 3; }
  @media screen and (min-width: 768px) {
    .mapboxgl-popup {
      margin-top: -66px; } }
  .mapboxgl-popup.mapboxgl-popup-anchor-top {
    margin-top: 0; }
  .mapboxgl-popup .mapboxgl-popup-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;
    text-align: center; }
    .mapboxgl-popup .mapboxgl-popup-content .place-infos {
      flex: 1 1 100%;
      font-weight: 800;
      padding: 8px 15px;
      color: #fff; }
      .mapboxgl-popup .mapboxgl-popup-content .place-infos .name {
        display: block; }
    .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button {
      position: relative;
      font-size: 2.4rem;
      flex: 1 1 100%;
      height: 32px;
      text-indent: -9999px;
      order: 1; }
      .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button::before, .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button::after {
        content: '';
        height: 2px;
        width: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #23395B; }
      .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button::before {
        transform: translate(-50%, -50%) rotate(45deg); }
      .mapboxgl-popup .mapboxgl-popup-content .mapboxgl-popup-close-button::after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    .mapboxgl-popup .mapboxgl-popup-content button:not(.selected):not(.trash-button):hover {
      background-color: rgba(0, 0, 0, 0.05) !important; }

.places-list {
  padding-top: 130px; }
  .places-list p {
    padding: 10px 30px;
    font-size: 1.2rem; }
  .places-list li.city {
    background-color: rgba(35, 57, 91, 0.2);
    text-transform: uppercase;
    padding: 5px 15px;
    font-size: 1.2rem;
    font-weight: 800; }
  .places-list li.place {
    display: flex;
    align-items: center;
    padding: 10px 30px; }
    .places-list li.place:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.2); }
    .places-list li.place .pin {
      margin-right: 10px; }
    .places-list li.place .name {
      font-weight: 800; }
    .places-list li.place .distance {
      white-space: nowrap;
      display: block;
      margin-left: auto;
      font-size: 1.2rem; }
  .places-list.no-place {
    display: flex;
    align-items: center;
    height: 100vh;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px; }
    .places-list.no-place i {
      display: block;
      font-size: 10rem;
      color: #fff;
      margin-bottom: 20px; }
    .places-list.no-place p {
      font-size: 1.8rem;
      padding: 0;
      margin-bottom: 10px; }
      .places-list.no-place p strong {
        font-size: 2rem; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none; }
  *:focus {
    outline: 0; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  background-color: #B9E3C6;
  color: #23395B;
  touch-action: manipulation; }

.App {
  width: 100vw;
  height: 100vh; }

a:focus,
button:focus {
  box-shadow: 0 0 0 3px rgba(185, 227, 198, 0.4); }

.reset-search-button,
.close-modal-button {
  position: absolute;
  text-indent: -9999px;
  background: transparent;
  border: 0;
  top: 0;
  right: 0;
  z-index: 2; }
  .reset-search-button::before, .reset-search-button::after,
  .close-modal-button::before,
  .close-modal-button::after {
    content: '';
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%; }
  .reset-search-button::before,
  .close-modal-button::before {
    transform: translate(-50%, -50%) rotate(45deg); }
  .reset-search-button::after,
  .close-modal-button::after {
    transform: translate(-50%, -50%) rotate(-45deg); }

.close-modal-button {
  width: 62px;
  height: 62px; }
  @media screen and (min-width: 768px) {
    .close-modal-button {
      width: 102px;
      height: 102px; } }
  .close-modal-button::before, .close-modal-button::after {
    width: 32px;
    background-color: #fff; }

.reset-search-button {
  width: 40px;
  height: 40px;
  z-index: 2; }
  .reset-search-button::before, .reset-search-button::after {
    width: 18px;
    background-color: #23395B; }

.mapboxgl-popup .favorite-button {
  border: 0;
  width: 50%;
  height: 32px;
  border-radius: 0; }

.mapboxgl-popup .trash-button {
  background-color: #D81E5B;
  color: #fff;
  border: 0;
  border-radius: 0;
  flex: 1 1 50%;
  padding: 5px 10px; }

.btn {
  background-color: #FFFD98;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  color: #23395B;
  font-weight: 400;
  padding: 10px 20px;
  margin: 10px 0;
  transition: .3s all;
  display: block;
  width: 100%;
  border-radius: 2px;
  line-height: 20px;
  text-decoration: none; }
  @media screen and (min-width: 768px) {
    .btn {
      max-width: 320px;
      display: inline-block;
      margin: 0; } }
  .btn.squared {
    padding: 10px 0;
    width: 43px;
    height: 43px; }
  .btn.secondary {
    background-color: rgba(255, 255, 255, 0.8); }
  .btn.red {
    color: #fff;
    background-color: #D81E5B; }
  .btn.facebook {
    background-color: #fff;
    position: relative;
    padding-left: 60px; }
    .btn.facebook i {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      color: #fff;
      background-color: #23395B;
      border: 1px solid #fff;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px; }

.App .mapboxgl-ctrl-group > button, .App .mapboxgl-ctrl-group > button:hover {
  background-color: #FFFD98; }

.no-style {
  background: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 10px 20px; }

.AppHeader > .actions::before {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: rgba(35, 57, 91, 0.7);
  z-index: 6;
  transition: .3s all;
  opacity: 0;
  visibility: hidden; }

.AppHeader > .actions button {
  width: 30px;
  height: 30px;
  display: block;
  padding: 0;
  outline: none;
  border: 0;
  background-color: #FFFD98;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 7;
  top: 25px;
  right: 30px; }
  @media screen and (min-width: 768px) {
    .AppHeader > .actions button {
      top: 35px;
      right: 90px; } }
  .AppHeader > .actions button i {
    line-height: 30px; }
  .AppHeader > .actions button:not(.star-button) {
    opacity: 0;
    visibility: hidden; }
  .AppHeader > .actions button.star-button {
    z-index: 8; }
    .AppHeader > .actions button.star-button::before {
      content: '★'; }

.AppHeader > .actions.active::before {
  top: -200px;
  right: -200px;
  width: 400px;
  height: 400px;
  opacity: 1;
  visibility: visible; }

.AppHeader > .actions.active button {
  opacity: 1;
  visibility: visible; }

.AppHeader > .actions.active button.mode-button {
  right: 90px; }
  @media screen and (min-width: 768px) {
    .AppHeader > .actions.active button.mode-button {
      top: 90px;
      right: 30px; } }

.AppHeader > .actions.active button.settings-button {
  top: 85px;
  right: 90px; }
  @media screen and (min-width: 768px) {
    .AppHeader > .actions.active button.settings-button {
      top: 35px;
      right: 30px; } }

.AppHeader > .actions.active button.filter-button {
  top: 85px; }
  @media screen and (min-width: 768px) {
    .AppHeader > .actions.active button.filter-button {
      top: 90px; } }

.AppHeader > .actions.active button.star-button::before {
  display: none; }

input,
button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif;
  transition: .3s all;
  border-radius: 2px;
  width: 100%;
  max-width: 320px; }

button {
  cursor: pointer; }

input[type="email"],
input[type="text"],
input[type="password"] {
  color: #FFFD98;
  text-overflow: ellipsis;
  display: block;
  padding: 10px 20px;
  border: 1px solid #B9E3C6;
  background: rgba(185, 227, 198, 0.2);
  position: relative;
  z-index: 1;
  line-height: 20px; }
  input[type="email"]:focus,
  input[type="text"]:focus,
  input[type="password"]:focus {
    border-color: #FFFD98; }

::-webkit-input-placeholder {
  color: #B9E3C6;
  opacity: 1; }

:-ms-input-placeholder {
  color: #B9E3C6;
  opacity: 1; }

::-ms-input-placeholder {
  color: #B9E3C6;
  opacity: 1; }

::placeholder {
  color: #B9E3C6;
  opacity: 1; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-size: 1.6rem;
  border: 1px solid #FFFD98;
  -webkit-text-fill-color: #FFFD98;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s; }

.search {
  position: fixed;
  left: 30px;
  right: 30px;
  top: 80px;
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  z-index: 5; }
  @media screen and (min-width: 768px) {
    .search {
      max-width: 370px;
      width: 100%;
      left: auto;
      top: 30px;
      right: 150px; } }
  @media screen and (min-width: 1024px) {
    .search {
      max-width: 420px; } }
  .search input {
    padding: 10px 15px;
    max-width: inherit;
    background-color: rgba(255, 255, 255, 0.6);
    color: #23395B; }
    .search input::-webkit-input-placeholder {
      color: #23395B;
      opacity: 1; }
    .search input:-ms-input-placeholder {
      color: #23395B;
      opacity: 1; }
    .search input::-ms-input-placeholder {
      color: #23395B;
      opacity: 1; }
    .search input::placeholder {
      color: #23395B;
      opacity: 1; }
    .search input:focus {
      background-color: #fff; }
  .search .list-suggestions {
    display: block;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: #fff;
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    transition: .3s all; }
    .search .list-suggestions li {
      padding: 8px 15px;
      border-top: 1px solid rgba(0, 0, 0, 0.1); }
      .search .list-suggestions li strong {
        display: block; }
      .search .list-suggestions li div {
        opacity: .6;
        font-size: 1.2rem; }
  .search.with-value input {
    padding: 10px 40px 10px 15px; }

.list-radios {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 0;
  opacity: 0;
  visibility: hidden;
  transition: .3s all; }
  .list-radios.active {
    opacity: 1;
    visibility: visible; }

.radio-toggles {
  display: flex;
  justify-content: center; }
  .radio-toggles > div {
    position: relative;
    margin: 20px 10px; }
    .radio-toggles > div label {
      font-size: 2rem;
      display: block;
      border: 2px solid #fff;
      border-radius: 5px;
      cursor: pointer;
      padding: 8px 15px;
      transition: .3s all;
      position: relative;
      text-indent: -9999px;
      width: 60px;
      height: 60px; }
    .radio-toggles > div input {
      opacity: 0;
      position: absolute; }
      .radio-toggles > div input:checked + label {
        background-color: #fff; }
    .radio-toggles > div.radio-false label::before, .radio-toggles > div.radio-false label::after {
      content: '';
      height: 3px;
      width: 33px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #fff; }
    .radio-toggles > div.radio-false label::before {
      transform: translate(-50%, -50%) rotate(45deg); }
    .radio-toggles > div.radio-false label::after {
      transform: translate(-50%, -50%) rotate(-45deg); }
    .radio-toggles > div.radio-true label::after {
      content: '';
      opacity: 1;
      height: 30px;
      width: 15px;
      transform-origin: 50% 50%;
      border-left: 3px solid #fff;
      border-top: 3px solid #fff;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -68%) rotate(-135deg); }

.LoginPage,
.RegisterPage,
.PrivacyPage {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  text-align: center;
  background-image: url(/static/media/bg-login.9089dd8d.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%; }
  .LoginPage .content,
  .RegisterPage .content,
  .PrivacyPage .content {
    height: 100vh;
    display: flex;
    flex-direction: column; }
    .LoginPage .content .loginForm,
    .LoginPage .content .registerForm,
    .RegisterPage .content .loginForm,
    .RegisterPage .content .registerForm,
    .PrivacyPage .content .loginForm,
    .PrivacyPage .content .registerForm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      margin-top: 80px;
      padding: 0 30px; }
      .LoginPage .content .loginForm > *:not(:last-child),
      .LoginPage .content .registerForm > *:not(:last-child),
      .RegisterPage .content .loginForm > *:not(:last-child),
      .RegisterPage .content .registerForm > *:not(:last-child),
      .PrivacyPage .content .loginForm > *:not(:last-child),
      .PrivacyPage .content .registerForm > *:not(:last-child) {
        margin-bottom: 20px;
        margin-top: 0; }
      .LoginPage .content .loginForm .error,
      .LoginPage .content .registerForm .error,
      .RegisterPage .content .loginForm .error,
      .RegisterPage .content .registerForm .error,
      .PrivacyPage .content .loginForm .error,
      .PrivacyPage .content .registerForm .error {
        color: #e74c3c; }
      .LoginPage .content .loginForm input,
      .LoginPage .content .registerForm input,
      .RegisterPage .content .loginForm input,
      .RegisterPage .content .registerForm input,
      .PrivacyPage .content .loginForm input,
      .PrivacyPage .content .registerForm input {
        text-align: center; }

.PrivacyPage {
  padding: 150px 30px 50px;
  color: white;
  text-align: left; }
  .PrivacyPage p,
  .PrivacyPage ul {
    margin: 20px 0; }
  .PrivacyPage ul li {
    list-style: disc outside none;
    list-style: initial;
    margin: 10px 20px; }

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 200px;
  z-index: 10;
  -webkit-filter: drop-shadow(0 10px 30px black);
          filter: drop-shadow(0 10px 30px black); }
  .loader:before {
    content: '';
    display: block;
    padding-top: 100%; }
  .loader .circular {
    -webkit-animation: rotate 2s linear infinite;
            animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .loader .path {
    stroke: #fff;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: dash 1.5s ease-in-out infinite;
            animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round; }

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: #fff;
  text-align: center;
  z-index: 10;
  transition: .3s all ease-in-out;
  background-color: #23395B; }
  @media screen and (min-width: 768px) {
    .modal {
      padding: 0 100px; } }
  .modal.modalSettings {
    background-image: url(/static/media/bg-login.9089dd8d.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%; }
    .modal.modalSettings .user-infos p {
      margin-bottom: 30px; }
    .modal.modalSettings .avatar-settings {
      display: block;
      max-width: 150px;
      margin-bottom: 30px;
      color: #D81E5B; }
    .modal.modalSettings .app-version {
      font-size: 1rem;
      font-weight: 200;
      color: #FFFD98; }
  .modal.modalPlace {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%; }
    .modal.modalPlace.bar {
      background-color: #786FA6;
      background-image: url(/static/media/bg-bar.da27c42e.jpg); }
      .modal.modalPlace.bar .radio-toggles .radio-true input:checked + label::after {
        border-left-color: #786FA6;
        border-top-color: #786FA6; }
      .modal.modalPlace.bar .radio-toggles .radio-false input:checked + label::before,
      .modal.modalPlace.bar .radio-toggles .radio-false input:checked + label::after {
        background-color: #786FA6; }
      .modal.modalPlace.bar .button-action:hover {
        color: #786FA6;
        background-color: #fff; }
    .modal.modalPlace.coffee {
      background-color: #F19066;
      background-image: url(/static/media/bg-coffee.6e6abbac.jpg); }
      .modal.modalPlace.coffee .radio-toggles .radio-true input:checked + label::after {
        border-left-color: #F19066;
        border-top-color: #F19066; }
      .modal.modalPlace.coffee .radio-toggles .radio-false input:checked + label::before,
      .modal.modalPlace.coffee .radio-toggles .radio-false input:checked + label::after {
        background-color: #F19066; }
      .modal.modalPlace.coffee .button-action:hover {
        color: #F19066;
        background-color: #fff; }
    .modal.modalPlace.restaurant {
      background-color: #C44569;
      background-image: url(/static/media/bg-restaurant.c1d01dd4.jpg); }
      .modal.modalPlace.restaurant .radio-toggles .radio-true input:checked + label::after {
        border-left-color: #C44569;
        border-top-color: #C44569; }
      .modal.modalPlace.restaurant .radio-toggles .radio-false input:checked + label::before,
      .modal.modalPlace.restaurant .radio-toggles .radio-false input:checked + label::after {
        background-color: #C44569; }
      .modal.modalPlace.restaurant .button-action:hover {
        color: #C44569;
        background-color: #fff; }
    .modal.modalPlace .content-modal {
      padding-top: 20px; }
      .modal.modalPlace .content-modal > [class^="icon-"] {
        font-size: 6rem; }
      .modal.modalPlace .content-modal .address {
        opacity: 0.6;
        font-size: 1.2rem; }
      .modal.modalPlace .content-modal .open-closed {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
        background-color: rgba(35, 57, 91, 0.6);
        border-radius: 4px;
        padding: 3px 6px; }
      .modal.modalPlace .content-modal .list-infos-btn {
        display: flex;
        justify-content: center; }
        .modal.modalPlace .content-modal .list-infos-btn li {
          margin: 5px; }
          .modal.modalPlace .content-modal .list-infos-btn li a.btn {
            margin: 0;
            margin-top: 10px;
            width: 40px;
            height: 40px; }
      .modal.modalPlace .content-modal .criteria {
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1 1;
        position: relative; }
        .modal.modalPlace .content-modal .criteria ul {
          flex: 1 1;
          min-height: 280px; }
          .modal.modalPlace .content-modal .criteria ul .list-radios {
            text-align: center; }
            .modal.modalPlace .content-modal .criteria ul .list-radios > [class^="icon-"] {
              display: inline-block;
              font-size: 10rem;
              margin-bottom: 6px; }
        .modal.modalPlace .content-modal .criteria button.left,
        .modal.modalPlace .content-modal .criteria button.right {
          position: absolute;
          top: 50%;
          margin-top: -65px;
          transition: .3s all;
          z-index: 2; }
          .modal.modalPlace .content-modal .criteria button.left.hidden,
          .modal.modalPlace .content-modal .criteria button.right.hidden {
            opacity: 0;
            visibility: hidden; }
        .modal.modalPlace .content-modal .criteria button.left {
          left: 3px; }
        .modal.modalPlace .content-modal .criteria button.right {
          right: 3px; }
  .modal .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px 20px;
    margin: auto;
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    max-width: 375px;
    font-size: 2rem; }
    .modal .content-modal .actions {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 0 3px; }
      @media screen and (min-width: 768px) {
        .modal .content-modal .actions {
          display: block; } }
      .modal .content-modal .actions > * {
        margin-bottom: 10px; }
        @media screen and (min-width: 768px) {
          .modal .content-modal .actions > * {
            margin-bottom: 30px; } }
      .modal .content-modal .actions .flex-buttons {
        display: flex; }
        .modal .content-modal .actions .flex-buttons button + button {
          margin-left: 10px;
          flex: 0 0 100px; }

.stars-container {
  position: relative;
  display: inline-flex; }
  .stars-container .empty-stars,
  .stars-container .full-stars {
    display: flex; }
    .stars-container .empty-stars .star::before,
    .stars-container .full-stars .star::before {
      content: '★ '; }
  .stars-container .empty-stars {
    color: rgba(255, 255, 255, 0.4); }
  .stars-container .full-stars {
    position: absolute;
    color: #ffc700;
    top: 0;
    left: 0;
    overflow: hidden; }

.rate {
  display: flex;
  flex-direction: row-reverse;
  height: 46px;
  padding: 0 10px;
  margin: 10px 0 0; }
  .rate:not(:checked) > input {
    position: absolute;
    top: -9999px; }
  .rate:not(:checked) > label {
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 46px;
    line-height: 1;
    color: #fff; }
    .rate:not(:checked) > label:before {
      content: '★ '; }
    .rate:not(:checked) > label:hover,
    .rate:not(:checked) > label:hover ~ label {
      color: #deb217; }
  .rate > input:checked ~ label {
    color: #ffc700; }

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08; }

