.d-flex {
  display: flex;
}

.d-block {
  display: block;
}

.flex-1 {
  flex: 1 1 auto;
}