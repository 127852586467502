.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  color: $color-white;
  text-align: center;
  z-index: 10;
  transition: .3s all ease-in-out;
  background-color: $color-japaneseIndigo;

  @media screen and (min-width: 768px) {
    padding: 0 100px;
  }
  &.modalSettings {
    background-image: url(../../images/bg-login.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    .user-infos {
      p {
        margin-bottom: 30px;
      }
    }
    .avatar-settings {
      display: block;
      max-width: 150px;
      margin-bottom: 30px;
      color: $color-dogwoodRose;
    }
    .app-version {
      font-size: 1rem;
      font-weight: $font-weight-light;
      color: $color-canary;
    }
  }
  &.modalPlace {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &.bar {
      background-color: $color-bar;
      background-image: url(../../images/bg-bar.jpg);

      .radio-toggles {
        .radio-true {
          input:checked + label::after {
            border-left-color: $color-bar;
            border-top-color: $color-bar;
          }
        }
        .radio-false input:checked + label::before,
        .radio-false input:checked + label::after {
          background-color: $color-bar;
        }
      }
      .button-action:hover {
        color: $color-bar;
        background-color: $color-white;
      }
    }
    &.coffee {
      background-color: $color-coffee;
      background-image: url(../../images/bg-coffee.jpg);

      .radio-toggles {
        .radio-true {
          input:checked + label::after {
            border-left-color: $color-coffee;
            border-top-color: $color-coffee;
          }
        }
        .radio-false input:checked + label::before,
        .radio-false input:checked + label::after {
          background-color: $color-coffee;
        }
      }
      .button-action:hover {
        color: $color-coffee;
        background-color: $color-white;
      }
    }
    &.restaurant {
      background-color: $color-restaurant;
      background-image: url(../../images/bg-restaurant.jpg);

      .radio-toggles {
        .radio-true {
          input:checked + label::after {
            border-left-color: $color-restaurant;
            border-top-color: $color-restaurant;
          }
        }
        .radio-false input:checked + label::before,
        .radio-false input:checked + label::after {
          background-color: $color-restaurant;
        }
      }
      .button-action:hover {
        color: $color-restaurant;
        background-color: $color-white;
      }
    }
    .content-modal {
      padding-top: 20px;

      > [class^="icon-"] {
        font-size: 6rem;
      }
      .address {
        opacity: 0.6;
        font-size: 1.2rem;
      }
      .open-closed {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
        background-color: rgba($color-japaneseIndigo, 0.6);
        border-radius: 4px;
        padding: 3px 6px;
      }
      .list-infos-btn {
        display: flex;
        justify-content: center;

        li {
          margin: 5px;

          a.btn {
            margin: 0;
            margin-top: 10px;
            width: 40px;
            height: 40px;
          }
        }
      }
      .criteria {
        width: 100%;
        display: flex;
        align-items: center;
        flex: 1;
        position: relative;

        ul {
          flex: 1;
          min-height: 280px;

          .list-radios {
            text-align: center;

            > [class^="icon-"] {
              display: inline-block;
              font-size: 10rem;
              margin-bottom: 6px;
            }
          }
        }
        button.left,
        button.right {
          position: absolute;
          top: 50%;
          margin-top: -65px;
          transition: .3s all;
          z-index: 2;

          &.hidden {
            opacity: 0;
            visibility: hidden;
          }
        }
        button.left {
          left: 3px;
        }
        button.right {
          right: 3px;
        }
      }
    }
  }
  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 30px 20px;
    margin: auto;
    overflow-y: scroll;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    max-width: 375px;
    font-size: 2rem;

    .actions {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 0 3px;

      @media screen and (min-width: 768px) {
        display: block;
      }
      > * {
        margin-bottom: 10px;

        @media screen and (min-width: 768px) {
          margin-bottom: 30px;
        }
      }
      .flex-buttons {
        display: flex;

        button + button {
          margin-left: 10px;
          flex: 0 0 100px;
        }
      }
    }
  }
}
