.mapboxgl-ctrl {
  position: absolute;
  top: 25px;
  left: 30px;
  z-index: 2;

  @media screen and (min-width: 768px) {
    left: auto;
    top: 35px;
    right: 30px;
  }
}

.navigation-control {
  left: auto;
  top: auto;
  bottom: 20px;
  right: 20px;
}

.pin {
  border: 0;
  appearance: none;
  background: transparent;
  width: auto;
  position: relative;

  .favorite-flag {
    width: 18px;
    position: absolute;
    right: -5px;
    top: -5px;

    @media screen and (min-width: 768px) {
      width: 20px;
    }
  }
  img:not(.favorite-flag) {
    height: 52px;

    @media screen and (min-width: 768px) {
      height: 66px;
    }
  }
}

.mapboxgl-marker {
  z-index: 0;
  // Display selected pin on top of other pins
  &.selected {
    z-index: 1;
  }
  .pin {
    position: absolute;
    top: -52px;
    left: 0;
    transform: translate(-50%, 0);

    @media screen and (min-width: 768px) {
      top: -66px;
    }
  }
}

// Map popups
.mapboxgl-popup {
  max-width: 200px;
  margin-top: -52px;
  z-index: 3;

  @media screen and (min-width: 768px) {
    margin-top: -66px;
  }
  &.mapboxgl-popup-anchor-top {
    margin-top: 0;
  }
  .mapboxgl-popup-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    overflow: hidden;
    text-align: center;

    .place-infos {
      flex: 1 1 100%;
      font-weight: $font-weight-bold;
      padding: 8px 15px;
      color: #fff;

      .name {
        display: block;
      }
    }
    .mapboxgl-popup-close-button {
      position: relative;
      font-size: 2.4rem;
      flex: 1 1 100%;
      height: 32px;
      text-indent: -9999px;
      order: 1;

      &::before,
      &::after {
        content: '';
        height: 2px;
        width: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: $color-japaneseIndigo;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
    button:not(.selected):not(.trash-button):hover {
      background-color: rgba($color-black, 0.05) !important;
    }
  }
}
