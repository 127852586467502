@import 'utils/variables';
@import 'utils/animations';
@import 'utils/icons';
@import 'utils/display';
@import 'utils/typo';

@import 'partials/copyrights';
@import 'partials/filters';
@import 'partials/header';
@import 'partials/map';
@import 'partials/places-list';

@import 'general';
@import 'components/buttons';
@import 'components/forms';
@import 'components/loader';
@import 'components/modal';
@import 'components/rating-stars';